import React from 'react'
import {createRoot} from 'react-dom/client'
import 'regenerator-runtime'
import LoadingText from './components/LoadingText'
import './styles/LoadingText.css'
// import { Auth0Provider } from '@auth0/auth0-react'
import {PostHogConfig} from 'posthog-js'
import {PostHogProvider} from 'posthog-js/react'
import {OidcProvider} from './oidc'

// eslint-disable-next-line @typescript-eslint/no-unused-vars
window.addEventListener('vite:preloadError', _event => {
	window.location.reload() // for example, refresh the page
})

const App = React.lazy(() => import('./App'))

const options: Partial<PostHogConfig> = {
	api_host: 'https://eu.i.posthog.com',
	person_profiles: 'always',
	enable_heatmaps: true,
	persistence: 'localStorage+cookie',
	autocapture: true,
	rageclick: true,
	session_recording: {
		maskAllInputs: false,
		maskInputOptions: {
			password: true,
		},
	},
}

const Main: React.FC = () => {
	return (
		<React.StrictMode>
			<React.Suspense fallback={<LoadingText />}>
				<OidcProvider fallback={<LoadingText />}>
					<PostHogProvider
						apiKey={import.meta.env.VITE_POSTHOG_API_KEY}
						options={options}
					>
						<App />
					</PostHogProvider>
				</OidcProvider>
			</React.Suspense>
		</React.StrictMode>
	)
}

const app = document.querySelector('#app')
if (app) createRoot(app).render(<Main />)
